import React, { FC, useEffect, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';
import EventTime from './EventTime';
// @ts-ignore
import { Biconomy } from '@biconomy/mexa';
import * as Sentry from '@sentry/react';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import Label from 'components/Label/Label';
import ModalLoading from 'components/ModalLoading';
import ModalSimple from 'components/ModalSimple';
import { useUser } from 'context/UserContext';
import { ethers } from 'ethers';
import i18next from 'i18next';
//import { magic } from 'libs/magic';
import moment from 'moment';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { FormattedNumber, IntlProvider } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useHistory, useParams } from 'react-router-dom';
import Input from 'shared/Input/Input';
import Minus from 'shared/PlusMinus/Minus';
import Plus from 'shared/PlusMinus/Plus';
import analytics from 'utils/analytics';
import axiosInstance, { axiosWP } from 'utils/api';
import AccordionInfo from './AccordionInfo';
import ReactPixel from 'react-facebook-pixel';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { useMagic } from 'context/MagicProvider';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const stripePromise = loadStripe(stripePublicKey);

export interface NftDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

export interface SideComponent {
  loading: boolean;
  isVisible: boolean;
  title?: string;
  message?: string;
  payment?: boolean;
}

export interface CheckoutFormType {
  onError: (value: string | undefined) => void;
  onSubmit: (value: string | undefined) => void;
}

interface MintResult {
  error?: string;
  hash?: string;
  formatedTokenIds?: number[];
}

export interface Contract {
  id: number;
  abi: object;
  name: string;
  price: number;
  about: string;
  address: string;
  network: string;
  askEmail: boolean;
  isActive: boolean;
  afterHourSales: boolean;
  soldOut: boolean;
  useBiconomy: boolean;
  promoCode: boolean;
  producerName: string;
  description: string;
  eventDateTime: string;
  networkScanner: string;
  municipalityTaxPercent: number;
  stateTaxPercent: number;
  maxTokensPerUser: number;
  imageDisplayFormat: string;
  currency: string;
  country: string;
  platformFeePercent: number;
  metaPixelID: string;
  customEvent: boolean;
  customColorHex: string;
  customLogoImage:{
    data: {
      attributes: {
        url: string;
      };
    };
  };
  customBannerImage:{
    data: {
      attributes: {
        url: string;
      };
    };
  };
  mainImage: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
  settings: {
    groupFormat?: string;
    countType?: string;
    inputs?: [
      {
        title: string;
        value: string;
        required: boolean;
        type: string;
        placeholder: string;
        options?: [
          {
            value: string;
            name: string;
          },
        ];
      },
    ];
  };
  tiers: [
    {
      id: number;
      name: string;
      price: number;
      feePlatform: number;
      quantity: number;
      customDate: boolean;
      startDate: string;
      endDate: string;
    },
  ];
  photos: [
    {
      id: number;
      hasGallery: boolean;
      totalPhotos: number;
    },
  ];
}

interface UserInputData {
  title: string;
  value: string;
  required: boolean;
  placeholder: string;
  userData?: string;
}

interface SmartContractEvent {
  event: string;
  args: {
    tokenId: object;
  };
}

interface PaymentOptionsProps {
  contract: Contract;
  price: number;
  fee: number;
  tax: number;
  promoDisc: number;
}

interface InputOptionsProps {
  contract: Contract;
  ticketCount?: number;
  groupFormat?: string;
}

interface CheckoutProps {
  contract: Contract;
  id: string;
}

interface PageParams {
  id: string;
}

const mintTicket = async (
  ticketCount: number,
  biconomy: any,
  address: string,
  abi: any,
  useBiconomy: boolean,
  userAddress: string,
  network: string
): Promise<MintResult> => {

  let attempts = 0;
  let prevNonce = 0;
  const retryLimit = 10;
  let returnValue = {};

  while (attempts < retryLimit) {

    try {
      if(useBiconomy){
        const provider = new ethers.providers.Web3Provider(biconomy);
        const { chainId } = await provider.getNetwork();
        if (chainId != 80002 && chainId != 137) {
          console.log('User is in incorred network');
          attempts = retryLimit;
          /*return {
            error:
              'Your wallet is in a incorrect network. Please change wallet network to polygon network.',
          };*/
          returnValue = {
            error:
              'Your wallet is in a incorrect network. Please change wallet network to polygon network.',
          };
        }
  
        analytics.track('mint_started');
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(address, abi, signer);
        const txn = await connectedContract.mint(ticketCount);
        const txReceipt = await txn.wait();
  
        const mintEvents = txReceipt.events.filter(
          (event: SmartContractEvent) => event.event === 'Transfer'
        );
        
        var formatedTokenIds: number[] = [];
  
        for (let i = 0; i < mintEvents.length; i++) {
          let token = mintEvents[i].args.tokenId;
          formatedTokenIds.push(parseInt(token));
        }
  
        analytics.track('successful_mint');
        attempts = retryLimit;
        //return { hash: txn.hash, formatedTokenIds };
        returnValue = { hash: txn.hash, formatedTokenIds };
      }else{
        
        //const userInfo = await magic?.user.getInfo();
        const provider = new ethers.providers.JsonRpcProvider(network == "polygon" || network == "mumbai" ? process.env.REACT_APP_RPC_TARGET : process.env.REACT_APP_RPC_TARGET_BASE);
        /*const { chainId } = await provider.getNetwork();
        if (chainId != 80002 && chainId != 137) {
          console.log('User is in incorred network');
          attempts = retryLimit;
          /*return {
            error:
              'Your wallet is in a incorrect network. Please change wallet network to polygon network.',
          };/
          returnValue = {
            error:
              'Your wallet is in a incorrect network. Please change wallet network to polygon network.',
          };
        }else{*/
          const connectedContract = new ethers.Contract(address, abi, provider);
          //@ts-ignore
          const wallet = new ethers.Wallet(network == "polygon" || network == "mumbai" ? process.env.REACT_APP_WSK : process.env.REACT_APP_WSK_BASE, provider);
          let nonce = await provider.getTransactionCount(wallet.address);
          let gasfee = (await provider.getGasPrice()).toNumber();
          let priorityGas = (await provider.getFeeData()).maxFeePerGas?.toNumber();

          if (nonce != 0 && nonce <= prevNonce) {
            nonce = prevNonce + 1;
          }

          prevNonce = nonce;
          
          let higherGas = 40000000000;
          //@ts-ignore
          if(priorityGas > gasfee){
            //@ts-ignore
            higherGas = priorityGas;
          }else{
            higherGas = Math.round(gasfee * (1 + (10/100)));
          }
    
          let rawTxn = await connectedContract.populateTransaction.mint(ticketCount,userAddress, {
              gasLimit: 10000000,
              maxPriorityFeePerGas: higherGas,
              maxFeePerGas: higherGas,
              //gasPrice: gasfee,
              nonce: nonce
          })
    
          let signedTxn = (await wallet).sendTransaction(rawTxn);
          analytics.track('mint_started');
    
          let reciept = (await signedTxn).wait();
          if (reciept) {
              let hash = (await signedTxn).hash;
              let logs = (await reciept).logs;
    
              var formatedTokenIds: number[] = [];
    
              let logsSize = 0;
              if(network == "polygon" || network == "mumbai"){
                logsSize = logs.length-1;
              }else{
                logsSize = logs.length;
              }
              for (let i = 0; i < logsSize; i++) {
                let token = logs[i].topics[3];
                let id = BigInt(token).toString();
                formatedTokenIds.push(parseInt(id));
              }
                  
              analytics.track('successful_mint');
              attempts = retryLimit;
              //return { hash:hash, formatedTokenIds:formatedTokenIds };
              returnValue = { hash:hash, formatedTokenIds:formatedTokenIds };
          } else {
              attempts = retryLimit;
              console.error("Error submitting transaction");
              Sentry.captureException("Error submitting transaction");
              throw Error('Please try again');
          }
        //}
      }
    } catch (error: any) {
      //console.error(error);
      if (error.reason === 'execution reverted: Sale is not active') {
        attempts = retryLimit;
        throw Error('Sale is not active');
      }

      if(error.reason.includes('fee') || error.reason.includes('nonce')){
        attempts++;
        Sentry.captureException(error);
        analytics.track('retrying');
      }else{
        Sentry.captureException(error);
        attempts = retryLimit;
        throw Error('Please try again');
      }

      if(attempts == retryLimit){
        throw Error('Please try again');
      }
    }
  }
  return returnValue;

};

function formatPrice(price: number) {
  return price / 100;
}

function formatPercentage(price: number) {
  return parseFloat(((price / 100).toFixed(4)));
}

function calculateTax(contract: Contract, total: number) {
  var stateTax = 0;
  if (contract.stateTaxPercent) {
    stateTax = parseInt(((contract.stateTaxPercent / 100) * total).toFixed(0)); // change this for math module for more accuracy
  }

  var municipalityTax = 0;
  if (contract.municipalityTaxPercent) {
    municipalityTax = parseInt(
      ((contract.municipalityTaxPercent / 100) * total).toFixed(0)
    );
  }

  var price = stateTax + municipalityTax;
  return price;
}

const style = {
  color: 'blue',
  backgroundImage: '',
};

const stripeStyleOptions = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#4cc3ff',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  },
};

const CardOption = () => {
  const { t, i18n } = useTranslation();
  const { user } = useUser();

  return (
    <div>
      <div className="my-4 border-none">
        <div>
          <p>{t('nft-item.enter-creditcard-information')}</p>
          <CardElement
            options={stripeStyleOptions}
            className="block w-full border-neutral-300 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-md h-11 px-4 py-3 shadow-lg border-0 dark:border"
          />
        </div>
      </div>
    </div>
  );
};

const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  contract,
  price,
  fee,
  tax,
  promoDisc,
}) => {
  const { t, i18n } = useTranslation();

  if (contract.price === 0 || promoDisc == 1) {
    return null;
  }
  let pColor = 'white';

  if(contract.customEvent){
    if(contract.customColorHex) {
      pColor = contract.customColorHex;
    }
  }

  return (
    <div>
      <CardOption />

      {/* ---------- 7 ----------  */}
      {/* PRICE */}

      <div className="pb-3 pt-6">
        <div className="flex flex-col sm:flex-row items-baseline p-6 border-2 rounded-md relative w-30" style={{borderColor:pColor}}>
          <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
            {t('nft-item.price')}
          </span>
          <span className="text-3xl xl:text-4xl font-semibold text-white">
            {/* ${price} */}
            {contract.currency === 'eur' ? (
              <IntlProvider locale={'es'}>
                <FormattedNumber
                  value={price}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            ) : (
              <IntlProvider locale={'us'}>
                <FormattedNumber
                  value={price}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            )}
          </span>
        </div>
      </div>
      {contract.currency === 'eur' ? (
        <div className="mb-4 border-solid border-2 border-sky-500 p-2 pt-1 rounded-md bg-gray-800 pb-2">
          <h2 className="mb-2 text-md text-sky-400 text-xl text-center">
            {t('fees-summary.transaction-summary')}
          </h2>
          {/*<div className="border-blue-50 grid grid-cols-2">
            <p className="text-right text-sm">
              {t('fees-summary.original-price')}
            </p>
            <p className="text-left pl-2 text-white">
              <IntlProvider locale={'es'}>
                <FormattedNumber
                  value={price}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            </p>
          </div>
          */}
          <div className="border-blue-50 grid grid-cols-2">
            <p className="text-right text-sm">
              {t('fees-summary.transaction-fee')}
            </p>
            <p className="text-left pl-2 text-white">
              <IntlProvider locale={'es'}>
                <FormattedNumber
                  value={fee}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            </p>
          </div>
          <div className="border-blue-50 grid grid-cols-2">
            <p className="text-right text-sm">
              {t('fees-summary.transaction-tax')}
            </p>
            <p className="text-left pl-2 text-white">
              <IntlProvider locale={'es'}>
                <FormattedNumber
                  value={tax}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            </p>
          </div>
          <div className="border-blue-50 grid grid-cols-2">
            <p className="text-right font-bold text-md">
              {t('fees-summary.total')}
            </p>
            <p className="text-left pl-2 text-white font-bold text-md">
              <IntlProvider locale={'es'}>
                <FormattedNumber
                  value={price + fee + tax}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            </p>
          </div>
        </div>
      ) : (
        <div className="mb-4 border-solid border-2 border-sky-500 p-2 pt-1 rounded-md bg-gray-800 pb-2">
          <h2 className="mb-2 text-md text-sky-400 text-xl text-center">
            {t('fees-summary.transaction-summary')}
          </h2>
          {/*<div className="border-blue-50 grid grid-cols-2">
            <p className="text-right text-sm">
              {t('fees-summary.original-price')}
            </p>
            <p className="text-left pl-2 text-white">
              <IntlProvider locale={'en'}>
                <FormattedNumber
                  value={price}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            </p>
          </div> */}
          <div className="border-blue-50 grid grid-cols-2">
            <p className="text-right text-sm">
              {t('fees-summary.transaction-fee')}
            </p>
            <p className="text-left pl-2 text-white">
              <IntlProvider locale={'en'}>
                <FormattedNumber
                  value={fee}
                  style="currency"
                  currency={contract.currency}
                  minimumFractionDigits={2}
                    maximumFractionDigits={2}
                />
              </IntlProvider>
            </p>
          </div>
          <div className="border-blue-50 grid grid-cols-2">
            <p className="text-right text-sm">
              {t('fees-summary.transaction-tax')}
            </p>
            <p className="text-left pl-2 text-white">
              <IntlProvider locale={'en'}>
                <FormattedNumber
                  value={tax}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            </p>
          </div>
          <div className="border-blue-50 grid grid-cols-2">
            <p className="text-right font-bold text-md">
              {t('fees-summary.total')}
            </p>
            <p className="text-left pl-2 text-white font-bold text-md">
              <IntlProvider locale={'en'}>
                <FormattedNumber
                  value={price + fee + tax}
                  style="currency"
                  currency={contract.currency}
                />
              </IntlProvider>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const ContractInput: React.FC<InputOptionsProps> = ({
  contract,
  ticketCount,
  groupFormat,
}) => {
  const [roleInputDisplay, setInputDisplay] = useState('hidden');
  const { user } = useUser();
  const { magic,switchBase, switchPolygon, network  } = useMagic()

  useEffect(() => {
    populateFields();
  }, [user]);

  async function populateFields(){
    if(user){
      let emailInput: HTMLInputElement = document.getElementById('email') as HTMLInputElement;
      let phoneInput: HTMLInputElement = document.getElementById('phone') as HTMLInputElement;

      const userInfo = await magic?.user.getInfo();
      const walletEmail = userInfo?.email;
      const walletPhone = userInfo?.phoneNumber;

      if(emailInput) emailInput.value = walletEmail ? walletEmail : '';
      if(phoneInput) phoneInput.value = walletPhone ? walletPhone : '';
    }
  }

  async function loginFirst(event: any) {
    //const isLoggedIn = await magic.user.isLoggedIn();
    if (!user?.address){
      if(localStorage.getItem('magic')=="true"){
        localStorage.setItem('magic', 'false');
        window.location.reload();
        return;
      }else{
        let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
        wcbutton.click();
        let modalFullName: HTMLElement = document.getElementById(event.target.id) as HTMLElement;
        modalFullName.blur();
        return;
      }
    }
  }

  function validateRole(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      value: string;
    };
    if (target.value == 'Other') {
      setInputDisplay('block');
    } else {
      setInputDisplay('hidden');
    }
  }

  if (!contract.settings?.inputs) {
    return null;
  }

  // Group input for individual user information
  if (
    groupFormat === 'merged' &&
    contract.settings.countType === 'perItem' &&
    ticketCount
  ) {
    const createArray = (n: number) => Array.from({ length: n }, (_, i) => i);
    const inputAmount = createArray(ticketCount);

    return (
      <div>
        {inputAmount.map((input, index) => {
          return (
            <div>
              <div
                className="grid grid-cols-1"
                key={index + 'perItem' + (ticketCount + 50)}
              >
                <div>
                  <label className="block mb-2">
                    <Label>{'Name'}</Label>
                    <Input
                      placeholder={'Enter Name'}
                      type="text"
                      className="mt-1"
                      id={'name' + index}
                      name={'name' + index}
                      required={true}
                      onChange={(e) => {
                        loginFirst(e);
                      }}
                      onClick={(e) => {
                        loginFirst(e);
                      }}
                    />
                  </label>
                </div>
              </div>
              <div
                className="grid grid-cols-1"
                key={index + 'perItem' + (ticketCount + 100)}
              >
                <div>
                  <label className="block mb-2">
                    <Label>{'Email'}</Label>
                    <Input
                      placeholder={'Enter Email'}
                      type="text"
                      className="mt-1"
                      id={'email' + index}
                      name={'email' + index}
                      required={true}
                      onChange={(e) => {
                        loginFirst(e);
                      }}
                      onClick={(e) => {
                        loginFirst(e);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      {contract.settings.inputs.map((input, index) => {
        if (input.type === 'single-select-option') {
          return (
            <div className="grid grid-cols-1" key={index}>
              <div>
                <label className="block mb-2">
                  <Label>{input.title}</Label>
                </label>
                <select
                  name={input.value}
                  required={input.required}
                  onChange={validateRole}
                  id={'id-' + input.value}
                  style={{
                    fontSize: '0.875rem',
                    borderRadius: '0.375rem',
                    marginBottom: '8px',
                    width: '100%',
                    backgroundColor:
                      'rgba(var(--c-neutral-900), var(--tw-bg-opacity))',
                    borderColor:
                      'rgba(var(--c-neutral-700), var(--tw-border-opacity))',
                  }}
                >
                  {input.options ? (
                    input.options.map((_input, _index) => (
                      <option value={_input.value} key={_index}>
                        {_input.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>

                <label className={`block mb-2 ${roleInputDisplay}`}>
                  <Label>{'Enter Other ' + input.title}</Label>

                  <Input
                    required={
                      roleInputDisplay == 'block' && input.required
                        ? true
                        : false
                    }
                    name={'other-' + input.value}
                    placeholder={input.placeholder}
                    type="text"
                    className="mt-1"
                    id={'id-' + input.value}
                  />
                </label>
              </div>
            </div>
          );
        }

        return (
          <div className="grid grid-cols-1 rounded-md" key={index}>
            <div>
              <label className="block mb-2">
                <Label>{input.title}</Label>
                <Input
                  placeholder={input.placeholder}
                  type={input.value == "email"? "email":"text"}
                  className="mt-1"
                  id={input.value}
                  name={input.value}
                  required={input.required}
                  onChange={(e) => {
                    loginFirst(e);
                  }}
                  onClick={(e) => {
                    loginFirst(e);
                  }}
                />
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const InputOptions: React.FC<InputOptionsProps> = ({
  contract,
  ticketCount,
}) => {
  if (!contract.settings?.inputs) {
    return null;
  }

  if (contract.settings.groupFormat === 'merged') {
    return (
      <ContractInput
        contract={contract}
        ticketCount={ticketCount || 0}
        groupFormat={'merged'}
      />
    );
  }

  return <ContractInput contract={contract} ticketCount={ticketCount} />;
};

const Checkout: React.FC<CheckoutProps> = ({ contract, id }) => {
  const { t, i18n } = useTranslation();
  const [is_checked, set_is_checked] = useState(false);
  const [promoApplied, setPromoApplied] = useState(false);
  const [ticketType, setTicketType] = useState(contract.tiers.length > 0 ? contract.tiers[0].name : '');
  const [basePrice, setBasePrice] = useState(contract.price);
  const [baseFee, setBaseFee] = useState(contract.platformFeePercent);
  const [price, setPrice] = useState(formatPrice(basePrice));
  const [ticketCount, setTicketCount] = useState(1);

  const { user } = useUser();

  const [fee, setFee] = useState(price * (formatPercentage(contract.platformFeePercent)));
  const [tax, setTax] = useState(price * ((formatPercentage(contract.stateTaxPercent))+(formatPercentage(contract.municipalityTaxPercent))));
  const [discount, setDiscount] = useState(0.0);
  const [promoInput, setPromoInput] = useState('Enter Promo Code');
  const [promoMsg, setPromoMsg] = useState('');

  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [biconomy, setBiconomy] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [borderColor, setBorderColor] = useState('white');

  const [personalizeLogo, setPersonalizeLogo] = useState('');
  const { magic,switchBase, switchPolygon, network  } = useMagic()


  const toggle_payment = () => {
    set_is_checked(!is_checked);
    console.log(is_checked);
    //ReactPixel.track('accepted_terms');
    analytics.track('accepted_terms');

  };

  const BuyButton = (contract: Contract) => {
    const { t, i18n } = useTranslation();

    return (
      <div>
        <div className="mt-8 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
          <ButtonPrimary
            className="flex-1"
            translate={backgroundColor}
            
            onClick={() => {
              analytics.track('buy_button_selected');
              //ReactPixel.track('buy_button_clicked');
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 12H14"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-2.5">
              {contract.price === 0 || discount == 1
                ? t('large-card-to-purchase.register-button')
                : t('large-card-to-purchase.buy-ticket')}
            </span>
          </ButtonPrimary>
        </div>
      </div>
    );
  };


  const PromoCodeSection = (contract: Contract) => {
    const { t, i18n } = useTranslation();

    async function applyPromo(){
      try{
        if(!promoApplied){
          setPromoMsg("");
  
          let promo: HTMLInputElement = document.getElementById('promoCodeInput') as HTMLInputElement;
          if(promo.value != '') {
            setPromoInput(promo.value);
          }else{
            setPromoMsg("Field can't be empty");
            return;
          }
          
          const query = {
            contractAddress: contract.address,
            code: promo.value,
            ticketQty: ticketCount,
            pkey: process.env.REACT_APP_PKEY,
          };
  
          const body = qs.stringify(query);
          const response = await axiosWP.post(
            '/event_creation/promo.php',
            body,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );
  
          if (response?.data?.su === 200) {
            analytics.track('promo_applied');
            ReactPixel.track('promo_applied');

            var disc = formatPercentage(response?.data?.discount);
            var discPrice = parseFloat((price-(price*disc)).toFixed(2));
            setDiscount(disc);
            setPromoApplied(true);
            setPrice(discPrice);
            setFee(parseFloat((discPrice * (formatPercentage(contract.platformFeePercent))).toFixed(2)));
            setTax(parseFloat((discPrice * ((formatPercentage(contract.stateTaxPercent))+(formatPercentage(contract.municipalityTaxPercent)))).toFixed(2)));
          } else {
            setPromoInput('Enter Promo Code');
            setPromoMsg(response?.data?.mssg);
          }
        }
      }catch (error: any) {
        Sentry.captureException(error);
      }
    }

    return (
      <div>
        {
          contract.promoCode && 
          <div className="grid grid-cols-1 rounded-md">
            <div>
              <label className="block mb-2">
                <Label>Promo Code</Label>
                <div className="grid grid-cols-2 gap-4 rounded-md">
                  <div>
                  <Input
                  placeholder={promoInput}
                  type="text"
                  className="mt-1"
                  id="promoCodeInput"
                  name="promoCode"
                />
                  </div>
                  <div>
                  
              <a onClick={applyPromo} className='flex-1 nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
              Apply
              </a>
                  </div>
                </div>
                <span className="block text-sm mt-2 sm:text-base text-red-700" style={{ fontSize: "13px" }}>
                    {promoMsg}
                </span>
              </label>
            </div>
          </div>
        }
      </div>
    );
  };

  useEffect(() => {
    // Get payment intent
    const fetchData = async () => {
      // this request is redundant as main request is main in primary component. Issue with component update

      var { data: response1 } = await axiosInstance.get('/api/contracts/' + id+ '?populate=*');
      var contract = response1.data.attributes;

      if(contract.customEvent){
        if(contract.customColorHex) {
          setBackgroundColor(contract.customColorHex);
          setBorderColor(contract.customColorHex);
        }
        if(contract.customLogoImage.data) {
          setPersonalizeLogo(contract.customize.logo.data.attributes.url);
        }
      }

      if(contract.metaPixelID!=''){
        ReactPixel.init(contract.metaPixelID);
        ReactPixel.pageView();
      }
      
      
      analytics.track('event',{'Id': id});
      

      var price = parseInt(contract.price);

      setPrice(formatPrice(price));
      setBasePrice(contract.price);
      setBaseFee(contract.platformFeePercent);
      setFee(formatPrice(price) * (formatPercentage(contract.platformFeePercent)));
      setTax(formatPrice(price) * ((formatPercentage(contract.stateTaxPercent))+(formatPercentage(contract.municipalityTaxPercent))));

      if (contract.price !== 0) {
        var paymentIntent = await axiosInstance.post(
          '/api/create-payment-intent',
          {
            firstName: 'John',
            lastName: 'Doe',
            price: contract.price,
            currency: contract.currency,
          }
        );

        if (paymentIntent.data.statusCode == 200) {
          setPaymentIntentId(paymentIntent.data.id);
          setClientSecret(paymentIntent.data.client_secret);
        } else {
          console.log(
            'Error ocurred setting payment intent client secret',
            paymentIntent.data
          );
        }
      }
    };

    fetchData().catch((error) => {
      console.error(error);
      Sentry.captureException(error);
    });
  }, []);


  if (magic?.rpcProvider && !biconomy && contract.useBiconomy) {
    const debug =
      process.env.REACT_APP_NODE_ENV === 'production' ? false : true;

    const biconomyInstance = new Biconomy(magic?.rpcProvider, {
      debug,
      apiKey: process.env.REACT_APP_BICONOMY_API_KEY || '',
    });

    setBiconomy(biconomyInstance);
  }

  // Stripe
  const stripe = useStripe();
  const elements = useElements();

  // User
  const history = useHistory();
  const [message, setMessage] = useState<SideComponent>({
    isVisible: false,
    loading: false,
    payment: contract.price === 0 || discount == 1 ? false : true
  });

  const [loadingMessage, setLoadingMessage] = useState("");
  const [loadingPayment, setLoadingPayment] = useState(false);


  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoadingMessage(
      contract.price === 0 || discount == 1 ? t('large-card-to-purchase.creating-unique-collectible') : t('large-card-to-purchase.processing-payment')
    );
    setLoadingPayment(contract.price === 0 || discount == 1 ? false : true);

    setMessage({ 
      isVisible: false, 
      loading: true
    });

    const target = event.target as typeof event.target & {
      [key: string]: {
        [key: string]: string;
      };
    };

    var userInputs: UserInputData[] = [];
    if (
      contract.settings.groupFormat === 'merged' &&
      contract.settings?.inputs
    ) {
      for (let i = 0; i < ticketCount; i++) {
        var updatedInput: any = {
          userData: {
            ['email' + i]: target['email' + i].value,
            ['name' + i]: target['name' + i].value,
          },
        };
        userInputs.push(updatedInput);
      }
    } else if (contract.settings?.inputs) {
      contract.settings.inputs.forEach((input) => {
        var updatedInput: UserInputData = input;
        if (target[input.value].value === 'Other') {
          updatedInput.userData = target['other-' + input.value].value;
        } else {
          updatedInput.userData = target[input.value].value;
        }
        userInputs.push(updatedInput);
      });
    }


    const initializeMint = async (
      total: number,
      account: string,
      orderId: number,
      email: string,
      name: string,
      userInputs: UserInputData[]
    ) => {
      try {
        const {
          error: mintError,
          hash: txn,
          formatedTokenIds,
        } = await mintTicket(
          ticketCount,
          biconomy,
          contract.address,
          contract.abi,
          contract.useBiconomy,
          user?.address ? user?.address : '',
          contract.network
        );

        if (mintError) {
          Sentry.captureException(mintError);
          setMessage({
            isVisible: true,
            title: 'Mint Error',
            message: mintError,
            loading: false,
          });
          return;
        }

        if (!txn) {
          Sentry.addBreadcrumb({ category: 'User', message: 'User: ' + email });
          Sentry.captureException(
            'Error ocurred generating transaction. Minting error'
          );
          setMessage({
            isVisible: true,
            title: 'Mint Error',
            message:
              'Error ocurred generating transaction. Plase contact dbloks support.',
            loading: false,
          });
          return;
        }

        // Check if transaction is in production or testing
        var url = contract.networkScanner + '/tx/' + txn;

        // Create order in dBloks
        const order = {
          data: {
            email,
            name,
            total,
            quantity: ticketCount,
            transactionHash: txn,
            blockchainTransactionUrl: url,
            inputs: userInputs,
            contract: contract.id,
          },
        };

        const { data: orderCreated } = await axiosInstance.post(
          '/api/orders',
          order
        );

        if (orderCreated.data.id) {
          analytics.track('created_order');
        } else {
          Sentry.captureException(orderCreated.data);
          //console.log('Error ocurred creating order:', orderCreated);
          analytics.track('failed_order_creation');
        }

        var formatPrice = parseFloat((total / 100).toFixed(2));
        var pricePerNFT = parseFloat((basePrice / 100).toFixed(2));

        var subtotal = ticketCount * basePrice;

        if(promoApplied){
          //formatPrice = formatPrice-(formatPrice*discount);
          pricePerNFT = pricePerNFT-(pricePerNFT*discount);
          subtotal = subtotal - parseInt(
            (subtotal * discount).toFixed(0)
          );
        }

        var totalFee = parseInt(
          (subtotal * (formatPercentage(baseFee))).toFixed(0)
        );
        var totalTax = parseInt(
          (subtotal * ((formatPercentage(contract.stateTaxPercent))+(formatPercentage(contract.municipalityTaxPercent)))).toFixed(0)
        );

        const query = {
          url,
          email,
          orderId,
          transactionHash: txn,
          tokenId: formatedTokenIds,
          userAddress: account,
          nftQty: ticketCount,
          pricePerNFT: pricePerNFT,
          subt: parseFloat((subtotal / 100).toFixed(2)),
          fee: parseFloat((totalFee / 100).toFixed(2)),
          tax: parseFloat((totalTax / 100).toFixed(2)),
          totalPrice: formatPrice,
          contractAddress: contract.address,
          imgUrl: contract.mainImage.data.attributes.url,
          bannerImg: contract.customBannerImage.data ? contract.customBannerImage.data.attributes.url : "",
          eventName: contract.name,
          language: i18next.language,
          pkey: process.env.REACT_APP_PKEY,
          
        };

        // Send Email
        const body = qs.stringify(query);
        const response = await axiosWP.post(
          '/event_creation/MintMail.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        if (response?.data?.su === 200) {
          analytics.track('sent_mint_email');
          ReactPixel.track('order_completed');
        } else {
          Sentry.captureException(response?.data);
        }

        setMessage({ isVisible: false, loading: false });
        setPromoApplied(false);

        history.push('/success-registration?transactionUrl=' + url+'&event=' + contract.address+'&name=' + name);
      } catch (error: any) {
        setPromoApplied(false);
        console.error(error);
        Sentry.captureException(error);

        if (error.message === 'Sale is not active') {
          return history.push('/error-registration?message=' + error.message);
        }else if(error.message == 'Please try again')
        {
          return history.push('/error-registration?message=' + error.message);
        }
        history.push('/error-registration');
      }
    };

    if (!user) {
      console.log('Make sure you have a wallet connected!');
      setMessage({
        isVisible: true,
        title: 'Please login',
        message: 'Make sure you have connected your email',
        loading: false,
      });
      return;
    }


    // TODO: If no wallet is connected it should ask for wallet connection
    // @ts-ignore
    /*if (userInfo.email.length === 0) {
      console.log('No wallet connected');
      setMessage({
        isVisible: true,
        title: 'Please login',
        message: 'Please connect your email to complete transaction.',
        loading: false,
      });
      return;
    }*/

    var name = target.name ? target.name.value : '';
    var email = target.email ? target.email.value : '';

    if (contract.price === 0 || discount == 1) {

      //Verify ticket qty

      const bodyTks = qs.stringify({
        userAddress: user?.address,
        nftQty: ticketCount,
        contractAddress: contract.address,
        maxTokens: contract.maxTokensPerUser,
        pkey: process.env.REACT_APP_PKEY,
        tixType: (ticketType == "" && contract.tiers.length > 0) ? contract.tiers[0].name : ticketType
      });

        // Send Email
      const responseTks = await axiosWP.post(
        '/event_creation/VerifyTicketMax.php',
        bodyTks,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (responseTks?.data?.su === 200) {
        setMessage({
          isVisible: true,
          title: t('large-card-to-purchase.registration-already-message'),
          message: t('large-card-to-purchase.account-registered'),
          loading: false,
        });
        return;
      }


      const body = qs.stringify({
        email,
        name,
        userInputs,
        userAddress: user?.address,
        nftQty: ticketCount,
        pricePerNFT: 0,
        totalPrice: 0,
        contractAddress: contract.address,
        maxTokens: contract.maxTokensPerUser,
        language: i18next.language,
        pkey: process.env.REACT_APP_PKEY,
        acceptTC: is_checked,
        paymentID: "free",
        code: promoInput,
        promo: promoApplied,
        tixType: (ticketType == "" && contract.tiers.length > 0) ? contract.tiers[0].name : ticketType
      });


      // Send Email
      const response = await axiosWP.post(
        '/event_creation/EmailManager.php',
        body,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (response?.data?.su === 200) {
        console.log('Order email sent successfully', response?.data?.mssg);
      } else if (response?.data?.su === 401) {
        setMessage({
          isVisible: true,
          title: t('large-card-to-purchase.registration-already-message'),
          message: t('large-card-to-purchase.account-registered'),
          loading: false,
        });
        return;
      } else {
        Sentry.captureException(response.data);
        return setMessage({
          isVisible: true,
          title: 'Order Error',
          message: 'Order could not be created.',
          loading: false,
        });
      }

      return initializeMint(
        0,
        user?.address ? user?.address : '',
        response?.data?.mssg,
        email,
        name,
        userInputs
      );
    }

    // Validate stripe
    if (elements == null || stripe == null) {
      return;
    }

    //Verify ticket qty

    const bodyTks = qs.stringify({
      userAddress: user?.address,
      nftQty: ticketCount,
      contractAddress: contract.address,
      maxTokens: contract.maxTokensPerUser,
      pkey: process.env.REACT_APP_PKEY,
      tixType: (ticketType == "" && contract.tiers.length > 0) ? contract.tiers[0].name : ticketType
    });

    // Send Email
    const responseTks = await axiosWP.post(
      '/event_creation/VerifyTicketMax.php',
      bodyTks,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (responseTks?.data?.su === 200) {
      setMessage({
        isVisible: true,
        title: t('large-card-to-purchase.registration-already-message'),
        message: t('large-card-to-purchase.account-registered'),
        loading: false,
      });
      return;
    }

    // Update Stripe payment intent
    // const total = ticketCount * basePrice;
    // const finalPrice = (contract.price + contract.platformFeePercent);
    var total = ticketCount * basePrice;
    if(promoApplied){
      total = total - parseInt(
          (total * discount).toFixed(0)
        );
    }
    const totalFee = parseInt(
      (total * (formatPercentage(baseFee))).toFixed(0)
    );
    const totalTax = parseInt(
      (total * ((formatPercentage(contract.stateTaxPercent))+(formatPercentage(contract.municipalityTaxPercent)))).toFixed(0)
    );
    
    var totalWithFeeTax = total + totalFee + totalTax;
    
    const updatePaymentIntent = await axiosInstance.post(
      '/api/update-payment-intent',
      {
        paymentIntent: paymentIntentId,
        amount: totalWithFeeTax,
        currency: contract.currency,
      }
    );

    const card = elements.getElement(CardElement)!;
    const { error, paymentIntent: payment } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: card,
        },
        //receipt_email: userInfo.email ? userInfo.email : '',
      }
    );

    if (error) {
      Sentry.captureException(error); // TODO: Filter user related issues
      setMessage({
        isVisible: true,
        title: 'Card Error',
        message: error.message,
        loading: false,
      });
      return;
    }else{
      setLoadingMessage(
        t('large-card-to-purchase.creating-unique-collectible')
      );
      setLoadingPayment(false);
      setMessage({ 
        isVisible: false, 
        loading: true
      });
    }

    // Format Body
    const formatPrice = parseFloat((totalWithFeeTax / 100).toFixed(2));
    const body = qs.stringify({
      email,
      name,
      userInputs,
      userAddress: user?.address,
      nftQty: ticketCount,
      pricePerNFT: price,
      totalPrice: formatPrice,
      contractAddress: contract.address,
      maxTokens: contract.maxTokensPerUser,
      language: i18next.language,
      pkey: process.env.REACT_APP_PKEY,
      acceptTC: is_checked,
      paymentID: payment.id,
      code: promoInput,
      promo: promoApplied,
      tixType: (ticketType == "" && contract.tiers.length > 0) ? contract.tiers[0].name : ticketType
    });

    // Send Email
    const response = await axiosWP.post(
      '/event_creation/EmailManager.php',
      body,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response?.data?.su === 200) {
      analytics.track('sent_payment_email');
    } else {
      Sentry.captureException(response.data);
      return setMessage({
        isVisible: true,
        title: t('large-card-to-purchaseorder-error'),
        message: t('large-card-to-purchase.order-not-created'),
        loading: false,
      });
    }
    return initializeMint(
      totalWithFeeTax,
      user?.address ? user?.address : '',
      response?.data?.mssg,
      email,
      name,
      userInputs
    );
  };

  // Loading
  if (!contract.address) {
    return (
      <div className="flex h-screen justify-center items-center">
        <svg
          className="animate-spin text-[#0084c7]"
          width="100px"
          height="100px"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"
          />
        </svg>
      </div>
    );
  }

  // Past Event
  const isPastEvent =
    new Date(contract.eventDateTime) < new Date(moment().toISOString());

  if (isPastEvent && !contract.afterHourSales) {
    analytics.track('past_event_displayed');

    return (
      <form>
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {/* ---------- 6 ----------  */}
          <div className="py-9 border-none">
            <EventTime time={contract.eventDateTime} />
          </div>

          <ModalLoading
            show={message.loading}
            props={{
              title: loadingMessage,
              payment : loadingPayment
            }}
            onCloseModalDelete={() => {
              console.log('Selected close on loading modal');
            }}
          />

          <ModalSimple
            show={message.isVisible}
            props={{ title: message?.title, message: message?.message }}
            onCloseModalDelete={() =>
              setMessage({ isVisible: false, loading: false })
            }
          />

          <div className="mt-10">
            <AccordionInfo
              address={contract.address}
              description={contract.description}
            />
          </div>
        </div>
      </form>
    );
  }else if (contract.soldOut) {
    analytics.track('past_event_displayed');

    return (
      <form>
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {/* ---------- 6 ----------  */}
          <div className="py-9 border-none">
            <EventTime time={contract.eventDateTime} />
          </div>

          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold pt-2 pb-2">
                Sold Out
          </h2>

          <ModalLoading
            show={message.loading}
            props={{
              title: loadingMessage,
              payment : loadingPayment
            }}
            onCloseModalDelete={() => {
              console.log('Selected close on loading modal');
            }}
          />

          <ModalSimple
            show={message.isVisible}
            props={{ title: message?.title, message: message?.message }}
            onCloseModalDelete={() =>
              setMessage({ isVisible: false, loading: false })
            }
          />

          <div className="mt-10">
            <AccordionInfo
              address={contract.address}
              description={contract.description}
            />
          </div>
        </div>
      </form>
    );
  }

  analytics.track('future_event_displayed');

  function priceUpdate(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      value: number;
    };

    let tierSelected = document.getElementById("id-tiers") as HTMLSelectElement;

    contract.tiers.forEach((tier) => {
      if (tier.id == +tierSelected.value) {

        var newPrice: number = ticketCount * tier.price;
        var price = newPrice;
        var newFee = formatPrice(price) * (formatPercentage(tier.feePlatform));

        setPromoApplied(false);
        setDiscount(0.0);
        setPromoInput('Enter Promo Code');
        setPromoMsg("");
        setTicketType(tier.name);

        setPrice(formatPrice(price));
        setBasePrice(tier.price);
        setBaseFee(tier.feePlatform);
        setFee(newFee);
        setTax(formatPrice(price) * ((formatPercentage(contract.stateTaxPercent))+(formatPercentage(contract.municipalityTaxPercent))));
        return;
      }
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
        {/* ---------- 6 ----------  */}
        <div className="py-9 border-none">
          <EventTime time={contract.eventDateTime} />
        </div>

        <ModalLoading
          show={message.loading}
          props={{
            title: loadingMessage,
            payment: loadingPayment
          }}
          onCloseModalDelete={() => {
            console.log('Selected close on loading modal');
          }}
        />

        <ModalSimple
          show={message.isVisible}
          props={{ title: message?.title, message: message?.message }}
          onCloseModalDelete={() =>
            setMessage({ isVisible: false, loading: false })
          }
        />

        <PaymentOptions contract={contract} price={price} fee={fee} tax={tax} promoDisc={discount}/>
        <PromoCodeSection {...contract} />

        <InputOptions contract={contract} ticketCount={ticketCount} />

        {contract.tiers.length > 0 ? (
          <div>
            <div className="grid grid-cols-1">
              <div>
                <label className="block mb-2">
                  <Label>Selecciona Categoria</Label>
                </label>
                <select
                  name="tiersSelect"
                  required
                  onChange={priceUpdate}
                  id="id-tiers"
                  style={{
                    fontSize: '0.875rem',
                    borderRadius: '0.375rem',
                    marginBottom: '8px',
                    width: '100%',
                    backgroundColor:
                      'rgba(var(--c-neutral-900), var(--tw-bg-opacity))',
                    borderColor:
                      'rgba(var(--c-neutral-700), var(--tw-border-opacity))',
                  }}
                >
                  {contract.tiers ? (
                    contract.tiers.map((input, index) => (
                      <option value={input.id} key={input.id}>
                        {input.name}
                      </option>
                    ))
                  ) : (
                    <option value=""></option>
                  )}
                </select>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div className="pb-9 pt-10">
          {/* grid grid-cols-3 */}
          <div className="flex items-center justify-center">
            <Minus
              btnClassName="w-11 h-11 text-xl mr-6"
              onClick={() => {
                if (ticketCount > 1) {
                  var newTicketCount: number = ticketCount - 1;
                  var newPrice: number = newTicketCount * basePrice;
                  var price = newPrice;

                  setPromoApplied(false);
                  setDiscount(0.0);
                  setPromoInput('Enter Promo Code');
                  setPromoMsg("");

                  setPrice(formatPrice(price));
                  setFee(
                    formatPrice(price) * (formatPercentage(baseFee))
                  );
                  setTax(formatPrice(price) * ((formatPercentage(contract.stateTaxPercent))+(formatPercentage(contract.municipalityTaxPercent))));
                  setTicketCount(newTicketCount);
                  analytics.track('purchase_screen_quantity_decreased');
                  //ReactPixel.track('ticket_decrease');
                }
              }}
            />

            <div className="flex flex-col sm:flex-row items-baseline py-6 px-12 border-2 rounded-md relative w-30" style={{borderColor:borderColor}}>
              <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
                {t('large-card-to-purchase.quantity')}
              </span>
              <span className="text-3xl xl:text-4xl font-semibold">
                {ticketCount}
              </span>
            </div>

            <Plus
              btnClassName="w-11 h-11 text-xl ml-6"
              onClick={() => {
                if (ticketCount < contract.maxTokensPerUser) {
                  var newTicketCount: number = ticketCount + 1;
                  var newPrice: number = newTicketCount * basePrice;
                  var price = newPrice;

                  setPromoApplied(false);
                  setDiscount(0.0);
                  setPromoInput('Enter Promo Code');
                  setPromoMsg("");

                  setPrice(formatPrice(price));
                  setTicketCount(newTicketCount);
                  setFee(
                    formatPrice(price) * (formatPercentage(baseFee))
                  );
                  setTax(formatPrice(price) * ((formatPercentage(contract.stateTaxPercent))+(formatPercentage(contract.municipalityTaxPercent))));

                  analytics.track('purchase_screen_quantity_increase');
                  //ReactPixel.track('ticket_increase');
                }
              }}
            />
          </div>
          <div className="pt-6">
            <div className="mb-4 flex items-center border-solid border-2 border-sky-500 p-3 rounded-md">
              <input
                className="pl-3"
                type="checkbox"
                name="acceptTC"
                checked={is_checked}
                onChange={toggle_payment}
                required={true}
              />
              <label className="text-sm text-white-900 pl-4">
                {t('terms-of-use.accept-terms-checkbox')}{' '}
                <a
                  href={t('terms-of-use.link-of-page')}
                  className="text-sky-300 text-sm hover:underline font-bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('terms-of-use.terms-of-use')}{' '}
                </a>
                &{' '}
                <a
                  href={t('privacy-policy.link-of-page')}
                  className="text-sky-300 text-sm hover:underline font-bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('privacy-policy.privacy-policy')}.{' '}
                </a>
              </label>
            </div>
          </div>

          <BuyButton {...contract} />

          <div className="mt-10">
            <AccordionInfo
              address={contract.address}
              description={contract.description}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const NftPurchasePage: FC<NftDetailPageProps> = ({
  className = '',
  isPreviewMode,
}) => {
  let { id } = useParams<PageParams>();

  const { user } = useUser();
  const { magic,switchBase, switchPolygon, network  } = useMagic()


  /*const [clientSecret, setClientSecret] = useState(
    'pi_3LWyDlA7z1eMDaeK0sR79qAX_secret_FsDs3uMvNivwijCjBEQxOHiu2'
  );*/
  const [contract, setContract] = useState<Contract>({
    id: 0,
    abi: [{}],
    name: '',
    price: 0,
    about: '',
    address: '',
    network: '',
    askEmail: true,
    isActive: true,
    afterHourSales: false,
    soldOut: false,
    useBiconomy: false,
    promoCode: false,
    producerName: '',
    description: '',
    eventDateTime: '',
    networkScanner: '',
    currency: '',
    country: '',
    municipalityTaxPercent: 0,
    stateTaxPercent: 0,
    maxTokensPerUser: 4,
    imageDisplayFormat: 'portrait',
    platformFeePercent: 0,
    metaPixelID: '',
    customEvent: false,
    customColorHex: '',
    customLogoImage:{
      data: {
        attributes: {
          url: '',
        },
      },
    },
    customBannerImage:{
      data: {
        attributes: {
          url: '',
        },
      },
    },
    tiers: [
      {
        id: 0,
        name: '',
        price: 0,
        feePlatform: 0,
        quantity: 0,
        customDate: false,
        startDate: '',
        endDate: '',
      },
    ],
    photos: [
      {
        id: 0,
        hasGallery: false,
        totalPhotos: 0,
      },
    ],
    mainImage: {
      data: {
        attributes: {
          url: '',
        },
      },
    },
    settings: {},
  });

  useEffect(() => {
    // Get payment intent

    const LANGUAGE = i18next.language;
    const fetchData = async () => {
      var { data: res } = await axiosInstance.get(
        '/api/contracts/' + id + '?populate=*'
      );
      var contract = res.data.attributes;
      setContract(contract);
      if(contract.customEvent){
        if(contract.customColorHex) {
          let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
          wcbutton.style.backgroundColor = contract.customColorHex;
        }
      }
    };

    fetchData().catch((error) => {
      console.error(error);
      Sentry.captureException(error);
    });

    
  }, []); 

  useEffect(() => {
    const switchNetwork = async () => {
      if(contract.network === 'ethereum'){
        await switchBase();
      }else if(contract.network === 'polygon'){
        await switchPolygon();
      }
    };
    switchNetwork();
  }, [contract]);

  useEffect(() => {
    if(contract.customEvent){
      if(contract.customColorHex) {
        let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
        wcbutton.style.backgroundColor = contract.customColorHex;
      }
    }
  }, [user]);

  const options_ES: StripeElementsOptions = {
    //clientSecret,
    locale: 'es',
  };

  const options_US: StripeElementsOptions = {
    //clientSecret,
    locale: 'en',
  };

  const image = contract.mainImage.data
    ? contract.mainImage.data.attributes.url
    : '';
  const imageDisplayFormat =
    contract.imageDisplayFormat === 'square' ? 'aspect-w-12 aspect-h-12' : 'aspect-w-11 aspect-h-13';

  return (
    <div
      className={`nc-NftDetailPage  ${className}`}
      data-nc-id="NftDetailPage"
    >
      {/* MAIN  */}
      <main className="container flex">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
          {/* CONTENT */}
          <div className="space-y-8 lg:space-y-10">
            {/* HEADING */}
            <div className="space-y-5">
              <div className="flex justify-end items-center">
                {/* Chage on social media and payment changes */}
                {/* <LikeSaveBtns /> */}
              </div>
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                {contract.name}
              </h2>
            </div>
            <div className="relative">
              <NcImage
                src={image}
                containerClassName={`${imageDisplayFormat} rounded-md overflow-hidden`}
              />
            </div>

            {/* <AccordionInfo /> */}
          </div>

          {/* SIDEBAR */}

          {i18next.language === 'es' ? (
            <div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
              <Elements stripe={stripePromise} options={options_ES}>
                <Checkout contract={contract} id={id} />
              </Elements>
            </div>
          ) : (
            <div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
              <Elements stripe={stripePromise} options={options_US}>
                <Checkout contract={contract} id={id} />
              </Elements>
            </div>
          )}
        </div>
      </main>

      {/* SIDEBAR */}
      {contract.about ? (
        <main className="container flex-col mb-8">
          <div className="mb-4">
            <span className="text-2xl sm:text-2xl font-semibold"></span>
          </div>
          <article className="prose max-w-none prose-invert">
            <ReactMarkdown linkTarget={'_blank'}>
              {contract.about}
            </ReactMarkdown>
          </article>
        </main>
      ) : null}
    </div>
  );
};

export default NftPurchasePage;
