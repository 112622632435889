import { CustomLink } from "data/types";
import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "/", label: "Home" },
      { href: "/my-collectibles", label: "My Collectibles" },
      { href: "/create-event", label: "Create Event" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "mailto:dev@tiike.com", label: "Contact Us" },
      { href: "/terms-of-use", label: "Terms Of Use" },
      { href: "/privacy-policy", label: "Privacy Policy" },
      { href: "/about", label: "About Us" },
    ],
  },
  // {
  //   id: "4",
  //   title: "Community",
  //   menus: [
  //     { href: "#", label: "Discussion Forums" },
  //     { href: "#", label: "Code of Conduct" },
  //     { href: "#", label: "Community Resources" },
  //     { href: "#", label: "Contributing" },
  //     { href: "#", label: "Concurrent Mode" },
  //     { href: "#", label: "API Reference" },
  //   ],
  // },
];

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const renderWidgetMenuItem = () => {
    return (
      <>
      <div className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {t("footer-home.explore")}
        </h2>
        <ul className="mt-5 space-y-4">
          <li>
            <a
              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
              href="/"
            >
              {t("footer-home.home")}
            </a>
          </li>
        </ul>
        <ul className="mt-5 space-y-4">
          <li>
            <a
              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
              href="/my-collectibles"
            >
              {t("footer-home.my-collectibles")}
              
            </a>
          </li>
        </ul>
        <ul className="mt-5 space-y-4">
          <li>
            <a
              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
              href="/create-event"
            >
              {t("footer-home.create-event")}
            </a>
          </li>
        </ul>
      </div>
      <div className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
        {t("footer-home.resources")}
        </h2>
        <ul className="mt-5 space-y-4">
          <li>
            <a
              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
              href="mailto:dev@tiike.com"
            >
              {t("footer-home.contact-us")}
            </a>
          </li>
        </ul>
        <ul className="mt-5 space-y-4">
          <li>
            <a
              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
              href={t("terms-of-use.link-of-page")}
            >
              {t("footer-home.terms-of-use")}
            </a>
          </li>
        </ul>
        <ul className="mt-5 space-y-4">
          <li>
            <a
              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
              href={t("privacy-policy.link-of-page")}
            >
              {t("footer-home.privacy-policy")}
            </a>
          </li>
        </ul>
        <ul className="mt-5 space-y-4">
          <li>
            <a
              className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
              href="/about"
            >
              {t("footer-home.about-us")}
            </a>
          </li>
        </ul>
      </div>
       </>
      
    );
  };

  return (
    <div className="nc-Footer relative py-20 lg:pt-20 lg:pb-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
          <div className="col-span-3 md:col-span-1">
            <h2 className="text-sm">
              {t("footer-home.built")} &#128155; {t("footer-home.in-pr")}
            </h2>
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)[0]}
        <div className="grid grid-cols-2 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <h2 className="text-sm font-semibold text-neutral-700 dark:text-neutral-200">
              {t("footer-home.tech-stack")}
            </h2>
            <div className="grid gap-x-1 gap-y-3 grid-cols-2 pt-4">
              <a href="https://polygon.technology/home" target="_blank">
                <img
                  src="https://res.cloudinary.com/cloudbloks/image/upload/v1685068132/buildwith/polygon_fhwgbq.svg"
                  style={{ width: "100px", height: "auto", minWidth: "100px" }}
                ></img>
              </a>
              <a href="https://www.alchemy.com/" target="_blank">
                <img
                  src="https://res.cloudinary.com/cloudbloks/image/upload/v1685106900/buildwith/Alchemy_Logo_bzjzls.svg"
                  style={{ width: "100px", height: "auto", minWidth: "100px" }}
                ></img>
              </a>
              <a href="https://aws.amazon.com/" target="_blank">
                <img
                  src="https://res.cloudinary.com/cloudbloks/image/upload/v1685107122/buildwith/aws_ha8k2l.png"
                  style={{ width: "50px", height: "auto", minWidth: "50px" }}
                ></img>
              </a>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1">
            <h2 className="text-sm font-semibold text-neutral-700 dark:text-neutral-200">
              {t("footer-home.proud-alumni")}
            </h2>
            <div className="grid gap-x-1 gap-y-3 grid-cols-2 pt-4">
              <a href="https://elboricuaselasinventa.com/" target="_blank">
                <img
                  src="https://elboricuaselasinventa.com/wp-content/uploads/2021/05/pre-logo-w-300x300.png"
                  style={{ width: "50px", height: "auto", minWidth: "50px" }}
                ></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
